<template>
  <Container tag="section" space-y class="px-container sm:px-container-md">
    <AnimatedContainer class="relative aspect-square md:aspect-[1.6]">
      <!--  IMAGE -->
      <div
        ref="imageWrapper"
        class="transition-wrapper image-scroll-animation z-30 aspect-square md:aspect-[1.6]"
      >
        <AppImage
          v-if="image"
          loading="lazy"
          class="size-full object-cover"
          :src="image"
        />
      </div>

      <!-- LINK -->
      <div
        class="absolute inset-0 z-30 flex items-center justify-center transition-opacity duration-500"
        :class="{ 'opacity-0': animate }"
      >
        <button
          v-if="data.entry.url"
          type="button"
          role="link"
          :aria-label="`Navigate to ${title}`"
          class="slide-up group flex items-center gap-[0.5em] text-base font-medium text-gray-500 transition-colors typo-heading-sm before:absolute before:inset-0 hover:text-gray-300 md:typo-heading-lg"
          @click="handleClick(data.entry.url)"
        >
          <span>{{ title }}</span>
          <IconArrowRight
            class="w-[0.8em] min-w-3 transition-transform group-hover:translate-x-[0.5em]"
          />
        </button>
      </div>
    </AnimatedContainer>
  </Container>
</template>

<script setup lang="ts">
import type { MainComponentsTeaserImageFragment, AssetFragment } from '#gql'

const props = defineProps<{
  data: Enforce<MainComponentsTeaserImageFragment>
}>()

const router = useRouter()
const animate = ref(false)
const imageWrapper: Ref<HTMLElement | null> = ref(null)

const image = computed<AssetFragment | undefined>(() => {
  if (props.data.image) {
    return props.data.image
  }

  if (
    Array.isArray(props.data.entry?.replicator_heros) &&
    props.data.entry?.replicator_heros.length
  ) {
    return props.data.entry?.replicator_heros[0].image || null
  }

  return undefined
})

const hasTargetImage = computed(() => {
  if (
    Array.isArray(props.data.entry?.replicator_heros) &&
    props.data.entry?.replicator_heros.length
  ) {
    return !!props.data.entry?.replicator_heros[0].image
  }
  return false
})

const title = computed(() => {
  if (props.data.title) {
    return props.data.title
  }
  return props.data.entry?.title || ''
})

async function handleClick(url: string) {
  if (!url || !imageWrapper.value || animate.value) return

  // Check if the URL is external
  const isExternalUrl = useIsExternalUrl(url)

  // If so open in new tab
  if (isExternalUrl) {
    window.open(url, '_blank')
    return
  }

  if (!hasTargetImage.value) {
    // Navigate to the URL
    router.push(url)
    return
  }

  // Animate the text
  animate.value = true

  // get the client rect of the image
  const rect = imageWrapper.value.getBoundingClientRect()

  // // Set the image wrapper to fixed position with the same dimensions
  imageWrapper.value.style.position = 'fixed'
  imageWrapper.value.style.top = `${rect.top}px`
  imageWrapper.value.style.left = `${rect.left}px`
  imageWrapper.value.style.width = `${rect.width}px`
  imageWrapper.value.style.height = `${rect.height}px`
  // set aspect ratio auto
  imageWrapper.value.style.aspectRatio = 'auto'

  requestAnimationFrame(() => {
    if (!imageWrapper.value) return // need to check it here as well to avoid warnings
    imageWrapper.value.style.top = '0'
    imageWrapper.value.style.left = '0'
    imageWrapper.value.style.width = `${document.documentElement.clientWidth}px` // without scrollbar
    imageWrapper.value.style.height = `${window.innerHeight * 0.95}px`
  })

  await new Promise((resolve) => setTimeout(resolve, 500))

  // Set the flag to disable page transition
  const cookie = useCookie('imagePageTransition')
  cookie.value = 'true'
  // Navigate to the URL
  router.push(url)
}
</script>

<style scoped>
.transition-wrapper {
  transition:
    top 0.5s ease-in-out,
    left 0.5s ease-in-out,
    width 0.5s ease-in-out,
    height 0.5s ease-in-out;
}

.image-scroll-animation {
  scale: 0.9;
  animation: imageScroll linear forwards;
  animation-timeline: view();
  animation-range-end: 50%;
}

@keyframes imageScroll {
  to {
    scale: 1;
  }
}
</style>
